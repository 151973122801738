<template>
  <div class="popup_wrap bg_white" style="width:400px;"><!-- popup_wrap 팝을띄울 사이즈 참고용 : style="width:400px; height:300px;" -->
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont"><!-- popup_cont -->
      <div class="circle_icon check" style="margin:30px auto 0;"></div>
      <p class="msg text_center color_blue font_16 font_medium mt15">
        {{ $t('msg.ONEX010P060.001') }}
      </p><!-- 운임등록 요청 -->
      <p class="msg text_center mt15">
        {{ $t('msg.ONEX010P060.002') }}
      </p><!-- 담당 영업사원에게 운임등록 요청을 하시겠습니까? -->
      <div class="text_center mt30">
        <a class="button blue lg" type="button" @click.prevent="insertInvoiceReq()">{{ $t('msg.VOSD100_M5.003') }}<!-- 예 --></a>
        <a class="button gray lg" @click.prevent="$emit('close')" type="button">{{ $t('msg.VOSD100_M5.004') }}<!-- 아니요 --></a>
      </div>

    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import fare from '@/api/rest/trans/fare'

export default {
  name: 'FareRegisReqPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          invRno: '',
          index: '',
          rtnCode: ''
        }
      }
    }
  },
  data () {
    return {
      regData: {
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const TH = this

      if (!this.$ekmtcCommon.isNotEmpty(TH.parentInfo.blNo)) {
        this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
          TH.$emit('close')
        })
      }
    },
    insertInvoiceReq () {
      const TH = this

      TH.regData.blNo = TH.parentInfo.blNo
      TH.regData.invRno = TH.parentInfo.invRno
      TH.regData.index = TH.parentInfo.index
      TH.regData.rtnCode = TH.parentInfo.rtnCode

      fare.insertInvoiceReq(TH.regData).then(res => {
         if (res.headers.respcode === 'C0000') {
          this.$ekmtcCommon.alertDefault(TH.parentInfo.blNo + this.$t('msg.ONEX010P060.003')) //에 대한 운임요청이 접수되었습니다.
          this.$emit('close')
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX010P060.004')) //운임요청에 실패하였습니다. 담당자에게 문의바랍니다.
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
